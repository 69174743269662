import React from 'react'
import { Form } from 'react-bootstrap'


const Checkbox = (props) => {
  return (
    <>
      <Form.Group className={`${props.className} checkbox_input`} controlId={props.id}>
        <Form.Check
          onChange={props.onChange}
          value={props.value}
          name={props.name}
          disabled={props.disabled}
          type="checkbox"
          label={props.label}
          className='form-check'
          checked = {props.defaultChecked}
        />
        {props.error}
      </Form.Group>
    </>
  )
}

export default Checkbox
