import ReactPaginate from 'react-paginate';
import "./CustomPagination.scss"
import { PaginationNextArrowIcon, PaginationPrevArrowIcon } from '../../../assets/Svg/SvgImages';

const ReactPagination = ({ handlePageChange, forcePage, activityList, limit }) => (
  <div className="customPagination">
    <ReactPaginate
      previousLabel={<PaginationPrevArrowIcon />}
      nextLabel={<PaginationNextArrowIcon />}
      breakLabel="..."
      pageCount={Math.ceil(activityList / (limit || 10))}
      marginPagesDisplayed={5}
      pageRangeDisplayed={5}
      forcePage={forcePage || 0}
      onPageChange={handlePageChange}
      containerClassName="pagination"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="prevArrow"
      previousLinkClassName="page-link"
      nextClassName="nextArrow"
      nextLinkClassName="page-link"
      activeClassName="active"
    />
  </div>
);

export default ReactPagination;