import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from 'react-bootstrap'
import CommonSearch from '../../components/ui/CommonSearch/CommonSearch';
import { Link, useLocation } from 'react-router-dom';
import { BackarrowIcon } from '../../assets/Svg/SvgImages';
import SnigleWalletTable from './SnigleWalletTable/SnigleWalletTable';
import { ATS } from '../../features/ats/atsSlice';
import { useDebounce } from "../../hooks/useDebounce";

const PortfolioSingleWallet = (props) => {
    const limit = 2;
    const [pageNumber, setpageNumber] = useState(1);
    const [searchText, setsearchText] = useState('');
    const location = useLocation();
    const dispatch = useDispatch();
    const [walletAssetData, setWalletAssetData] = useState([]);
    const debounced = useDebounce(searchText, 500);

    const { wallet_id, wallet_name, vault_id } = location.state;
    console.log(location.state, wallet_id, wallet_name, vault_id); // output: "the-page-id"

    const getWalletAssetData = async () => {
        const param = { walletId: wallet_id, vaultId: vault_id, pageNumber: pageNumber, limit: limit }
        if (searchText !== '') {
            param.searchText = searchText
        }
        console.log("param", param);
        let result = await dispatch(ATS.fetchWalletAssetList(param))
        if (result.status === 200) {
            setWalletAssetData(result.data)

        }
    }
    const handleSearch = (e) => {
        setsearchText(e.target.value);
        setpageNumber(1)
    };

    useEffect(() => {
        getWalletAssetData()
    }, [pageNumber, debounced])
    console.log("walletAssetData", walletAssetData, pageNumber);
    return (
        <>
            <div className='portfolioSinglewallet'>
                <Container>
                    <div className='commonCardbox'>
                        <div className='backBtn'>
                            <Link to="/portfolio"><span><BackarrowIcon /></span> Back</Link>
                        </div>
                        <div className='mb-4 mb-md-5'>
                            <div className='dashHeading'>
                                <h3>{wallet_name ?? ''}<span className='ms-md-4'>{wallet_id ?? ''}</span></h3>
                            </div>
                            <CommonSearch className="walletSearch" placeholder="Search"
                                onChange={(e) => { handleSearch(e) }} />
                        </div>
                        <SnigleWalletTable
                            walletAssetData={walletAssetData}
                            pageNumber={pageNumber}
                            limit={limit}
                            setpageNumber={setpageNumber}
                        />
                    </div>
                </Container>
            </div>
        </>
    )
}

export default PortfolioSingleWallet
