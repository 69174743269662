import { Container } from 'react-bootstrap'
import noRecord from '../../../assets/images/NoRecord.png'
const NoRecord = ({text}) => {
  return (
    <Container>
      <div className="no_record_found">
        <img src={noRecord} alt="No Record" />
        <h4>No {text} Found</h4>
      </div>
    </Container>
  )
}

export default NoRecord
