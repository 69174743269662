import moment from 'moment-timezone';
import copy from 'copy-to-clipboard';

import { toastMsg } from "../components/common/Toast/Toast";
import { DAY_EXPIRE, TZ } from '../constants/constants';
export const parseJSON = value => JSON.parse(JSON.stringify(value));
export const toFix = (value, n) => {
    const v = value.toString().split('.');
    if (n <= 0) return v[0];
    let f = v[1] || '';
    if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
    while (f.length < 2) f += '0';
    return `${v[0]}.${f}`
}
export const inputDecimal = (value, decimals) => {
    // console.log('input ', value, decimals);
    let valData = value;
    if (Number(decimals) <= 0) {
        let getDicimal = value?.toString().split(".");
        valData = getDicimal[0];
        return valData;
    } else {
        if (
            value?.toString().includes(".") &&
            value?.toString().split(".")[1] !== ""
        ) {
            let getDicimal = value?.toString().split(".");
            let result = getDicimal[1].substring(0, Number(decimals));
            valData = getDicimal[0] + "." + result;
        }
        return valData;
    }
};
export const getDayOrderExpiryTime = () => {
    const currentDate = moment.tz(TZ).format('M/D/YYYY');
    return moment.tz(`${currentDate} ${DAY_EXPIRE}`, "M/D/YYYY h:mm a", TZ).diff(moment.tz(TZ), 'minutes');
}

export const copyToClipboard = (value) => {
    copy(value);
    toastMsg.success('Copied!');
};
export const getDateTimeEST = (dateTime = null, format = false) => {
    // let DateTime = moment().tz("America/New_York").format(format ? "DD/MM/YYYY HH:mm A" : "");
    // if (dateTime) DateTime = moment(dateTime).tz("America/New_York").format(format ? "DD/MM/YYYY HH:mm A" : "");
    // return DateTime
    let DateTime = moment().format(format ? "DD/MM/YYYY HH:mm A" : "");
    if (dateTime) DateTime = moment(dateTime).format(format ? "DD/MM/YYYY HH:mm A" : "");
    return DateTime;
}
export const getRandomColor = () => {
    // Generate a random hex color code
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const getTenDifferentColors = () => {
    const colors = [];
    for (let i = 0; i < 10; i++) {
        let color = getRandomColor();
        // Ensure the generated color is not already in the array
        while (colors.includes(color)) {
            color = getRandomColor();
        }
        colors.push(color);
    }
    return colors;
}

export const getColors = () => {
    const predefined = ["#00BE00", "#C52B2B", "#329BFF", "#FF5700", "#20325C"];
    return [...predefined, ...getTenDifferentColors()];
}