export const SMALLESTUNIT = 100000000;
export const totallimit = 10000000000000000;
export const DEFAULT_LAYOUT = {
  lg: [
    {
      i: "BuySellTab",
      x: 0,
      y: 0,
      h: 17.2,
      w: 5,
      minW: 3,
      minH: 1,
      isResizable: true,
      resizeHandles: ["se"],
    },
    {
      i: "Accounts",
      x: 0,
      y: 14,
      h: 10.8,
      w: 5,
      minW: 4,
      minH: 5,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OrderBook",
      x: 5,
      y: 0,
      h: 28.1,
      w: 6,
      minW: 3,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "TradeChart",
      x: 11,
      y: 0,
      h: 16.4,
      w: 13,
      minW: 6,
      minH: 6,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OpenOrder",
      x: 11,
      y: 5,
      h: 11.8,
      w: 13,
      minW: 8,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "WatchList",
      x: 37,
      y: 0,
      h: 15.1,
      w: 6,
      minW: 3,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },

    {
      i: "MarketTrades",
      x: 37,
      y: 14,
      h: 13.1,
      w: 6,
      minW: 3,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
  ],
  md: [
    {
      i: "BuySellTab",
      x: 0,
      y: 0,
      h: 16.7,
      w: 5,
      minW: 3,
      minH: 1,
      isResizable: true,
      resizeHandles: ["se"],
    },
    {
      i: "Accounts",
      x: 0,
      y: 14,
      h: 8.5,
      w: 5,
      minW: 4,
      minH: 5,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OrderBook",
      x: 5,
      y: 0,
      h: 25.2,
      w: 6,
      minW: 3,
      minH: 2,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "TradeChart",
      x: 11,
      y: 0,
      h: 13.5,
      w: 13,
      minW: 6,
      minH: 6,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "OpenOrder",
      x: 11,
      y: 5,
      h: 11.8,
      w: 13,
      minW: 8,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
    {
      i: "WatchList",
      x: 37,
      y: 0,
      h: 13.6,
      w: 6,
      minW: 3,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },

    {
      i: "MarketTrades",
      x: 37,
      y: 14,
      h: 11.7,
      w: 6,
      minW: 3,
      minH: 1,
      resizeHandles: ["se"],
      isResizable: true,
    },
  ],
};
export const DEFAULT_EMPTY_LAYOUT = {
  lg: [],
  md: [],
};
export const LAYOUT_LIST = [];
// export const LAYOUT_LIST = [
//   {
//     workspace: 'Workspace 1',
//     disable: false,
//     active: true,
//     layout: {
//       lg: [
//         {
//           i: "BuySellTab",
//           x: 0,
//           y: 0,
//           h: 17.2,
//           w: 5,
//           minW: 3,
//           minH: 1,
//           isResizable: true,
//           resizeHandles: ["se"],
//         },
//         {
//           i: "Accounts",
//           x: 0,
//           y: 14,
//           h: 10.8,
//           w: 5,
//           minW: 4,
//           minH: 5,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OrderBook",
//           x: 5,
//           y: 0,
//           h: 28.1,
//           w: 6,
//           minW: 3,
//           minH: 2,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "TradeChart",
//           x: 11,
//           y: 0,
//           h: 16.4,
//           w: 13,
//           minW: 6,
//           minH: 6,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OpenOrder",
//           x: 11,
//           y: 5,
//           h: 11.8,
//           w: 13,
//           minW: 8,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "WatchList",
//           x: 37,
//           y: 0,
//           h: 15.1,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },

//         {
//           i: "MarketTrades",
//           x: 37,
//           y: 14,
//           h: 13.1,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//       ],
//       md: [
//         {
//           i: "BuySellTab",
//           x: 0,
//           y: 0,
//           h: 16.7,
//           w: 5,
//           minW: 3,
//           minH: 1,
//           isResizable: true,
//           resizeHandles: ["se"],
//         },
//         {
//           i: "Accounts",
//           x: 0,
//           y: 14,
//           h: 8.5,
//           w: 5,
//           minW: 4,
//           minH: 5,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OrderBook",
//           x: 5,
//           y: 0,
//           h: 25.2,
//           w: 6,
//           minW: 3,
//           minH: 2,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "TradeChart",
//           x: 11,
//           y: 0,
//           h: 13.5,
//           w: 13,
//           minW: 6,
//           minH: 6,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OpenOrder",
//           x: 11,
//           y: 5,
//           h: 11.8,
//           w: 13,
//           minW: 8,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "WatchList",
//           x: 37,
//           y: 0,
//           h: 13.6,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },

//         {
//           i: "MarketTrades",
//           x: 37,
//           y: 14,
//           h: 11.7,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//       ],
//     }
//   },
//   {
//     workspace: 'Workspace 2',
//     disable: true,
//     active: false,
//     layout: {
//       lg: [
//         {
//           i: "BuySellTab",
//           x: 0,
//           y: 0,
//           h: 17.2,
//           w: 5,
//           minW: 3,
//           minH: 1,
//           isResizable: true,
//           resizeHandles: ["se"],
//         },
//         {
//           i: "Accounts",
//           x: 0,
//           y: 14,
//           h: 10.8,
//           w: 5,
//           minW: 4,
//           minH: 5,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OrderBook",
//           x: 5,
//           y: 0,
//           h: 28.1,
//           w: 6,
//           minW: 3,
//           minH: 2,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "TradeChart",
//           x: 11,
//           y: 0,
//           h: 16.4,
//           w: 13,
//           minW: 6,
//           minH: 6,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OpenOrder",
//           x: 11,
//           y: 5,
//           h: 11.8,
//           w: 13,
//           minW: 8,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "WatchList",
//           x: 37,
//           y: 0,
//           h: 15.1,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },

//         {
//           i: "MarketTrades",
//           x: 37,
//           y: 14,
//           h: 13.1,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//       ],
//       md: [
//         {
//           i: "BuySellTab",
//           x: 0,
//           y: 0,
//           h: 16.7,
//           w: 5,
//           minW: 3,
//           minH: 1,
//           isResizable: true,
//           resizeHandles: ["se"],
//         },
//         {
//           i: "Accounts",
//           x: 0,
//           y: 14,
//           h: 8.5,
//           w: 5,
//           minW: 4,
//           minH: 5,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OrderBook",
//           x: 5,
//           y: 0,
//           h: 25.2,
//           w: 6,
//           minW: 3,
//           minH: 2,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "TradeChart",
//           x: 11,
//           y: 0,
//           h: 13.5,
//           w: 13,
//           minW: 6,
//           minH: 6,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OpenOrder",
//           x: 11,
//           y: 5,
//           h: 11.8,
//           w: 13,
//           minW: 8,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "WatchList",
//           x: 37,
//           y: 0,
//           h: 13.6,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },

//         {
//           i: "MarketTrades",
//           x: 37,
//           y: 14,
//           h: 11.7,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//       ],
//     }
//   },
//   {
//     workspace: 'Workspace 3',
//     disable: true,
//     active: false,
//     layout: {
//       lg: [
//         {
//           i: "BuySellTab",
//           x: 0,
//           y: 0,
//           h: 17.2,
//           w: 5,
//           minW: 3,
//           minH: 1,
//           isResizable: true,
//           resizeHandles: ["se"],
//         },
//         {
//           i: "Accounts",
//           x: 0,
//           y: 14,
//           h: 10.8,
//           w: 5,
//           minW: 4,
//           minH: 5,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OrderBook",
//           x: 5,
//           y: 0,
//           h: 28.1,
//           w: 6,
//           minW: 3,
//           minH: 2,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "TradeChart",
//           x: 11,
//           y: 0,
//           h: 16.4,
//           w: 13,
//           minW: 6,
//           minH: 6,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OpenOrder",
//           x: 11,
//           y: 5,
//           h: 11.8,
//           w: 13,
//           minW: 8,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "WatchList",
//           x: 37,
//           y: 0,
//           h: 15.1,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },

//         {
//           i: "MarketTrades",
//           x: 37,
//           y: 14,
//           h: 13.1,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//       ],
//       md: [
//         {
//           i: "BuySellTab",
//           x: 0,
//           y: 0,
//           h: 16.7,
//           w: 5,
//           minW: 3,
//           minH: 1,
//           isResizable: true,
//           resizeHandles: ["se"],
//         },
//         {
//           i: "Accounts",
//           x: 0,
//           y: 14,
//           h: 8.5,
//           w: 5,
//           minW: 4,
//           minH: 5,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OrderBook",
//           x: 5,
//           y: 0,
//           h: 25.2,
//           w: 6,
//           minW: 3,
//           minH: 2,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "TradeChart",
//           x: 11,
//           y: 0,
//           h: 13.5,
//           w: 13,
//           minW: 6,
//           minH: 6,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "OpenOrder",
//           x: 11,
//           y: 5,
//           h: 11.8,
//           w: 13,
//           minW: 8,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//         {
//           i: "WatchList",
//           x: 37,
//           y: 0,
//           h: 13.6,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },

//         {
//           i: "MarketTrades",
//           x: 37,
//           y: 14,
//           h: 11.7,
//           w: 6,
//           minW: 3,
//           minH: 1,
//           resizeHandles: ["se"],
//           isResizable: true,
//         },
//       ],
//     }
//   }
// ];
// export const GRID_LAYOUT = LAYOUT_LIST[0];
export const SELECTED_LAYOUT = []//LAYOUT_LIST.filter(o => o.active);
export const GRID_LAYOUT = {}//SELECTED_LAYOUT[0].layout;
export const CURRENT_WORKSPACE = ''//SELECTED_LAYOUT[0].workspace.replace(/ /g, '_');

export const FONT_LAYOUT = {
  openOrder: 11,
  tableHead: 11,
  tableBody: 11,
  tickerHead: 13,
  tickerArrow: 15,
  tickerPrice: 12,
  minFont: 9,
};
