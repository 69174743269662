import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import loadingReducer from "../features/loading/loadingSlice";
import atsReducer from "../features/ats/atsSlice";
import peristReducer from "../features/persist/persistSlice";
import storage from "redux-persist/lib/storage";

const combinedReducer = combineReducers({
  loading: loadingReducer,
  ats: atsReducer,
  persist: peristReducer
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["persist"],
};
const persistedReducer = persistReducer(persistConfig, combinedReducer);

const rootReducer = (state, action) => {
  // if (action.type === "users/logoutUser") {
  //   //this is use for clearing persist reducer
  //   storage.removeItem("persist:root");
  //   state = undefined;
  //   window.location.reload();
  // }
  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer
});
