import { Provider } from "react-redux";
import io from "socket.io-client";
import { store } from "./app/store";
import Application from "./Application";
import { PersistGate } from "redux-persist/lib/integration/react";
import persistStore from "redux-persist/es/persistStore";
import Loader from "./components/common/LoaderCompoent/Loader";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { SOCKET_URL } from "./constants/constants";
import { useEffect } from "react";

export const socket = io(SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  transports: ["websocket"]
});

const persistor = persistStore(store);
const App = () => {
  // const theme  = useSelector((state) => state.theme.theme);
  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket handshake successfully!');
    })
    socket.on("disconnect", () => {
      console.log("Socket disconnected App");
      setTimeout(() => socket.connect(), 500);
    });

    socket.on("connect_error", (err) => {
      console.log(`Socket connect_error due to ${err}`);
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Loader />
        <Application />
      </PersistGate>
    </Provider>
  );
};

export default App;
