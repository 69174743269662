import { Tab } from 'bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Container, Tabs } from 'react-bootstrap'
import "./Setting.scss"
import CreateAlert from './CreateAlert/CreateAlert'
import ActiveAlertsTable from './ActiveAlertsTable/ActiveAlertsTable'
import ExpiredAlertsTable from './ExpiredAlertsTable/ExpiredAlertsTable'
import { useSelector } from 'react-redux'
import { parseJSON } from '../../../helpers/commonHelper'
import { ATS } from '../../../features/ats/atsSlice'

const Settings = () => {
    const [pairOptions, setPairOptions] = useState([]);
    const [alertList, setAlertList] = useState([]);
    const [expireAlertList, setExpireAlertList] = useState([]);
    const [pair, setPair] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState("0");
    const [editAlertData, setEditAlertData] = useState(null);
    const [cancelIndex, setCanceIndex] = useState(-1);
    const [isSubmitted, setIsSubmited] = useState(false);
    const [filterBody, setFilterBody] = useState({ pairName: '', status: 1, limit: 10, offset: 0, alert_on: "", trigger_alert: '', dateTime: '' })
    const [expireFilterBody, setExpireFilterBody] = useState({ pairName: '', status: 3, limit: 10, offset: 0 })
    const filterRef = useRef(filterBody);
    filterRef.current = filterBody;
    const pairList = useSelector(state => state?.ats?.pairList);
    const alerOnOptions = [
        { value: '', label: 'Select Condition' },
        { value: 'EQ', label: 'Equal' },
        { value: 'GT', label: 'Greater' },
        { value: 'LT', label: 'Less' }
    ]
    const triggerOptions = [
        { value: '', label: 'Select Trigger' },
        { value: 'ONCE', label: 'ONCE' },
        { value: 'REPEAT', label: 'REPEAT' },
    ]
    const fetchAlerts = (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                setIsSubmited(true);
                const response = await ATS.fetchAlerts(body);
                if (response.status === 200) {
                    setIsSubmited(false);
                    resolve(response.data)
                } else {
                    setIsSubmited(false);
                }
            } catch (error) {
                setIsSubmited(false);
            }
        })
    }
    useEffect(() => {
        if (pairList.length) {
            const pairs = [];
            pairList.forEach(ele => {
                pairs.push({ value: ele.pair_key, label: `${ele.otherCurrency}/${ele.baseCurrency}` })
            });
            setPairOptions(parseJSON(pairs));
            filterBody.pairName = pairs[0].value;
            expireFilterBody.pairName = pairs[0].value;
            setPair(parseJSON(pairs[0]));
            setFilterBody(filterBody);
            fetchAlerts(filterRef.current).then(result => {
                setAlertList(result);
            });
            fetchAlerts(expireFilterBody).then(result => {
                setExpireAlertList(result);
            });
        }
    }, [pairList]);
    const onChangeFilter = (data, isReset) => {
        if (isReset) {
            const filter = { pairName: pairOptions[0].value, status: 1, limit: 10, offset: 0, alert_on: "", trigger_alert: '', dateTime: '' };
            setPair(parseJSON(pairOptions[0]));
            setFilterBody(parseJSON(filter));
            filterRef.current = parseJSON(filter)
            fetchAlerts(filterRef.current).then(result => {
                setAlertList(result);
            });
        } else {
            filterBody.pairName = data.pair.value;
            filterBody.trigger_alert = data.trigger.value;
            filterBody.alert_on = data.condition.value;
            filterBody.dateTime = data.dateTime ? data.dateTime : "";
            setPair(parseJSON(data.pair));
            setFilterBody(parseJSON(filterBody));
            filterRef.current = parseJSON(filterBody);
            fetchAlerts(filterRef.current).then(result => {
                setAlertList(result);
            });
        }
    }
    const onExpireFilter = (data) => {
        expireFilterBody.pairName = data.pair.value;
        setExpireFilterBody(parseJSON(expireFilterBody));
        fetchAlerts(expireFilterBody).then(result => {
            setExpireAlertList(result);
        });
    }
    const cancelAlert = async (id, pairName, index) => {
        const body = { id, pairName };
        setCanceIndex(index);
        const response = await ATS.cancelAlert(body);
        if (response.status === 200) {
            setCanceIndex(-1);
            const index = alertList.findIndex(o => Number(o.id) === Number(o.id));
            if (index > -1) alertList.splice(index, 1);
            setAlertList(parseJSON(alertList));
            fetchAlerts(expireFilterBody).then(result => setExpireAlertList(result));
        } else {
            setCanceIndex(-1);
        }
    }
    const onAlertCreate = (result) => {
        if (result.isEdit || result.isCancel) {
            if (!result.isCancel) setActiveTabKey("1");
            setEditAlertData(null);
        }
        if (!result.isCancel) fetchAlerts(filterBody).then(result => setAlertList(result));
    }
    const onEditAlert = (data) => {
        console.log(data);
        data.pairName = pair.value;
        setEditAlertData(data);
        setActiveTabKey("0");
    }
    const onSetAlert = (e, key) => {
        if (e.target.attributes.length === 3) {
            setEditAlertData(null);
            setActiveTabKey(key)
        }
    }
    return (
        <>
            <div className='settingsPage'>
                <Container>
                    <Tabs
                        defaultActiveKey="alerts"
                        id="uncontrolled-tab-example"
                        className="borderTabs mb-4 mb-xl-5"
                    >
                        <Tab eventKey="alerts" title={`Alerts`}>
                            <Accordion defaultActiveKey="0" activeKey={activeTabKey}>
                                <Accordion.Item eventKey="0" className='accordian_cls' onClick={e => onSetAlert(e, "0")}>
                                    <Accordion.Header>Create Alert</Accordion.Header>
                                    <Accordion.Body>
                                        <CreateAlert onAlertCreate={onAlertCreate} editBody={editAlertData} onEditAlert={onAlertCreate} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" onClick={e => onSetAlert(e, "1")}>
                                    <Accordion.Header>Active Alerts <span className='activeAlerts greenbg'>{alertList.length}</span></Accordion.Header>
                                    <Accordion.Body >
                                        <ActiveAlertsTable conditionOptions={alerOnOptions} triggerOptions={triggerOptions} pairOptions={pairOptions} onChangeFilter={onChangeFilter} alertList={alertList} pairName={pair} loading={isSubmitted} cancelIndex={cancelIndex} onCancelAlert={cancelAlert} onEditAlert={onEditAlert} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" onClick={e => onSetAlert(e, "2")}>
                                    <Accordion.Header>Expired Alerts <span className='activeAlerts redbg'>{expireAlertList.length}</span></Accordion.Header>
                                    <Accordion.Body>
                                        <ExpiredAlertsTable pairOptions={pairOptions} alertList={expireAlertList} onFilter={onExpireFilter} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Tab>
                        {/* <Tab eventKey="profile" title="Profile Settings">
                            Profile Setting
                        </Tab> */}
                    </Tabs>
                </Container>
            </div>
        </>
    )
}

export default Settings
