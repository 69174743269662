import avalanche from "./assets/chainIcons/Avalanche.svg";
import ethereum from "./assets/chainIcons/Ethereum.svg";
import polygon from "./assets/chainIcons/Polygon.svg";
import optimism from "./assets/chainIcons/Optimism.svg";
import provenance from "./assets/chainIcons/provenence.png";
import onyx from "./assets/chainIcons/Ethereum.svg";
import Canton from "./assets/chainIcons/daml.png";
import solana from "./assets/chainIcons/solana.png";
import corda from "./assets/chainIcons/corda.png";
import aptos from "./assets/chainIcons/aptoslogo.png";
import base from "./assets/chainIcons/base-logo-in-blue.png";
import hedera from "./assets/chainIcons/hedera-hbar-logo.svg";
import ripple from "./assets/chainIcons/ripple.svg";
import Besu from "./assets/chainIcons/besu.png";
import near from "./assets/chainIcons/near_logo.svg";
import bitcoin from "./assets/chainIcons/bitcoin.svg";

export const blockchainOptions = [
  { label: "Select Blockchain" },
  { value: "Avalanche", label: "Avalanche", icon: avalanche },
  { value: "Ethereum", label: "Ethereum", icon: ethereum },
  { value: "Polygon", label: "Polygon", icon: polygon },
  { value: "Optimism", label: "Optimism", icon: optimism },
  { value: "Provenance", label: "Provenance", icon: provenance },
  { value: "Onyx", label: "Onyx", icon: onyx },
  { value: "Canton", label: "Canton", icon: Canton },
  { value: "Solana", label: "Solana", icon: solana },
  { value: "Corda", label: "Corda", icon: corda },
  { value: "Aptos", label: "Aptos", icon: aptos },
  { value: "Base", label: "Base", icon: base },
  { value: "Hedera", label: "Hedera", icon: hedera },
  { value: "Ripple", label: "Ripple", icon: ripple },
  { value: "Besu", label: "Besu", icon: Besu },
  { value: "Near", label: "Near", icon: near },
  { value: "Holesky", label: "Holesky", icon: ethereum },
  { value: "Bitcoin", label: "Bitcoin", icon: bitcoin },
];
