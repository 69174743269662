import React, { useEffect, useRef, useState } from 'react'
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { NotificationIcon } from '../../../assets/Svg/SvgImages';
import moment from 'moment';
import { ATS } from '../../../features/ats/atsSlice';
import { SOCKET } from '../../../constants/constants';
import { parseJSON } from '../../../helpers/commonHelper';
import { socket } from '../../../App';
import { toastMsg } from '../Toast/Toast';

const NotificationList = () => {
    const [loading, setLoading] = useState(false);
    const [isLoadMore, setIsloadMore] = useState(true);
    const [paginationBody, setPaginationBody] = useState({ offset: 0, limit: 10 });
    const [notificationList, setNotificationList] = useState([]);
    const notificationRef = useRef(notificationList);
    notificationRef.current = notificationList;
    const getNotifications = async () => {
        const response = await ATS.getNotifications(paginationBody);
        if (response.status === 200) {
            setNotificationList([...notificationList, ...response.data])
            return response.data;
        }
    }
    const loadMore = async () => {
        try {
            setPaginationBody({ offset: paginationBody.offset + 1, limit: paginationBody.limit });
            setLoading(true);
            const result = await getNotifications();
            if (result.length === 0) setIsloadMore(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }
    useEffect(() => {
        getNotifications();
    }, []);
    useEffect(() => {
        if (socket) {
            socket.off(SOCKET.USER_NOTIFICATION);
            socket.on(SOCKET.USER_NOTIFICATION, order => {
                if (order.type !== "EVENT") toastMsg.info(order.message);
                notificationRef.current.unshift(parseJSON(order));
                setNotificationList(parseJSON(notificationRef.current));
            });
        }
    }, [socket]);
    return (
        <>
            <Dropdown className="Notification_Dropdown" align="end">
                <Dropdown.Toggle variant="" id="dropdown-basic">
                    <NotificationIcon />
                    {/* <span className='Show_Notification_No'>3</span> */}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <ul>
                        {notificationList.map((item, i) => {
                            return (
                                <li key={i}>
                                    <h5>{item.title}  <small>{item.message}</small></h5>
                                    <div className=''>
                                        <p>{moment(item.created_at).format('DD/MM/YYYY')} <span>{moment(item.created_at).format('hh:mm A')}</span></p>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <div className='loadMorebtn'>
                        {
                            (notificationList.length >= paginationBody.limit && isLoadMore) ?
                                <Button type='button' disabled={loading} onClick={loadMore} className='btn-style w-100'>
                                    {loading ?
                                        <span className='LoaderIcon ps-2'>
                                            <Spinner animation="border" />
                                        </span>
                                        :
                                        'Load More'}
                                </Button>
                                :
                                <></>
                        }
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default NotificationList