import React, { Children } from 'react'
import "./CustomDropdown.scss"
import Dropdown from 'react-dropdown';

const CustomDropdown = (props) => {
    // const defaultOption = options[0];
    return (
        <>
            <div className='customDropdown'>
                {props?.icon && (
                    <span className='customIcon'>{props?.icon}</span>
                )}
                <Dropdown 
                    options={props?.options}
                    onChange={props?.onChange}
                    value={props?.defaultOption}
                    placeholder={props?.placeholder}
                    className={props?.className}
                />
            </div>
        </>
    )
}

export default CustomDropdown
