import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../common/Header/Header";

export const AuthLayout = (props) => {
    return (
        <>
            <div className="mainSitelayout">
                <Header />
                {/* <div>{props.children}</div> */}
                <Outlet />
            </div>
        </>
    );
}