import React, { useEffect, useState } from "react";
import "./ExpiredAlertsTable.scss";
import CommonTable from "../../../../components/common/CommonTable/CommonTable";
import { parseJSON } from "../../../../helpers/commonHelper";
import CustomSelect from "../../../../components/ui/CustomSelect/CustomSelect";
import { TZ } from "../../../../constants/constants";
import moment from "moment-timezone";

const ExpiredAlertsTable = ({ pairOptions, alertList, onFilter }) => {
  const [filterOptions, setFilterOptions] = useState({ pair: null })
  const onDropdownChange = (pair, key) => {
    filterOptions[key] = pair;
    setFilterOptions(parseJSON(filterOptions));
    onFilter(filterOptions);
  }
  useEffect(() => {
    if (pairOptions.length) {
      filterOptions.pair = pairOptions[0];
    }
  }, [pairOptions])
  const Tableheading = [
    "Symbol",
    "Price",
    "Trigger",
    "Expiration",
    "Message",
    "status",
  ];

  const activeAlertdata = [
    {
      symbol:
        pairOptions.length ?
          <CustomSelect
            placeholder=""
            value={filterOptions.pair}
            options={pairOptions}
            onChange={e => onDropdownChange(e, "pair")}
            className=""
          /> : <></>
      ,
      price: "",
      trigger: "",
      expiration: "",
      message: "",
      checkaction: true,
    },
  ];
  return (
    <>
      <div className="activeAlertstable expiredAlertsTable">
        <CommonTable className="innerCommontable AlertForm" fields={Tableheading}>
          {activeAlertdata.map((data, index) => {
            return (
              <tr key={index}>
                <td>
                  {data.symbol}

                </td>
                <td>{data.price}</td>
                <td>{data.trigger}</td>
                <td>{data.expiration}</td>
                <td>{data.message}</td>
                <td>
                  {data.expired && (
                    <span className="tableBtn redBG">Expired</span>
                  )}
                  {data.cancelled && (
                    <span className="tableBtn yellowBG">Cancelled</span>
                  )}
                </td>
              </tr>
            );
          })}
          {alertList.map((data, index) => {
            return (
              <tr key={index}>
                <td>
                  {filterOptions.pair.label}

                </td>
                <td>{data.price}</td>
                <td>{data.alert_on}</td>
                <td>{moment.tz(TZ).format("DD/MM/YYYY HH:mm A")}</td>
                <td>{data.message}</td>
                <td>
                  {data.status === 3
                    ?
                    <span className="tableBtn redBG">Expired</span>
                    :
                    <span className="tableBtn yellowBG">Cancelled</span>
                  }
                </td>
              </tr>
            );
          })}
        </CommonTable>
      </div>
    </>
  );
};

export default ExpiredAlertsTable;
