import React from 'react'
import './CustomSelect.scss'
import Select from 'react-select'

const CustomSelect = ({ className, menuIsOpen, defaultValue, onChange, options, name, label, placeholder, value, disabled }) => {
  return (
    <>
      <div className={`common_select ${className}`}>
        <div className='innerSelect'>
          {label && (
            <label>{label}</label>
          )}
          <Select
            defaultValue={defaultValue}
            onChange={onChange}
            options={options}
            classNamePrefix="select"
            menuIsOpen={menuIsOpen}
            isDisabled={disabled}
            name={name}
            value={value}
            placeholder={placeholder}
          />
        </div>
      </div>
    </>
  )
}

export default CustomSelect
