import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Exchange = React.lazy(() => import("./Exchange/Exchange"));
const Trade = React.lazy(() => import("./Exchange/TradingChart/TradeChart"));
const Login = React.lazy(() => import("./Exchange/Login/Login"));
const Dashboard = React.lazy(() => import("./Dashboard/Dashboard"));
const Settings = React.lazy(() => import("./Exchange/Settings/Settings"));

export { Exchange, Trade, Login, Dashboard, Settings };
