export const API_HOST = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const DATA_ENCRYPT_KEY = process.env.REACT_APP_DATA_ENCRYPT_KEY;
export const ALGO_SERVER = process.env.REACT_APP_ALGO_SERVER;
export const ALGO_INDEXER = process.env.REACT_APP_ALGO_INDEXER;
export const INDEXER_API = process.env.REACT_APP_INDEXER_API;
export const ALGOD_TOKEN = process.env.REACT_APP_ALGOD_TOKEN;
export const DISPENSER_LINK = process.env.REACT_APP_DISPENSER_LINK;
export const GLOWL_DECIMAL = 4;
export const EXCHANGE_NAME = "Oasis Pro Market";
export const ORDER_BOOK_SIZE = 50;
export const PRICE_SCALE_VALUE = 8;
export const MARKET_TRADES_SIZE = 50;
export const WITHDRAW_FEE = 0.001 * 10 ** 6;
export const APP_CLOSEOUT_FEE = 0.002 * 10 ** 6;
export const ORDER_BOOK_LENGTH = 16;
export const DAY_ORDER_EXPIRY_TIME = "16:30";
export const TZ = 'America/New_York';
export const DAY_EXPIRE = '4:30 pm';
export const ESTIMATE_GAS = 21000;
export const MESSAGES = {
    PAIR_ADDED_TO_FAVOURITE: 'Pair added to favorites!',
    PAIR_REMOVED_TO_FAVOURITE: 'Pair removed from favorites!',
};
export const SOCKET = {
    JOIN_ROOM: 'joinRoom',
    BUY_ORDERBOOK: "_BUY_ORDERBOOK",
    SELL_ORDERBOOK: "_SELL_ORDERBOOK",
    CANCEL_ORDER: "_CANCEL_ORDER_BOOK",
    TRADE_BOOK: "_TRADE_ORDERBOOK",
    SPEEDBUMP_TRADE_BOOK: "_SPEEDBUMP_TRADE_ORDERBOOK",
    LAST_TRADE_PRICE: "LAST_TRADE_PRICE",
    OPEN_ORDERS: "_ACTIVE_ORDERS",
    USER_CANCEL_ORDER: "_CANCEL_ORDER",
    USER_TRADE: "_TRADE_ORDERS",
    SPEEDBUMP_USER_TRADE: "_SPEEDBUMP_TRADE_ORDERS",
    USER_NOTIFICATION: "USER_NOTIFICATION",
    REPORT: "pnl_report_"
}
