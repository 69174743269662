import cogoToast from "cogo-toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class ToasterMsg {
  success = (message) => {
    let options = { position: "top-center", heading: "Success" };
    cogoToast.success(message, options);
  };

  error = (message) => {
    let options = { position: "top-center", heading: "Error" };
    cogoToast.error(message, options);
  };

  info = (message) => {
    let options = { position: "top-center", heading: "Info" };
    cogoToast.info(message, options);
  };
}

export const toastMsg = new ToasterMsg();
class Toaster {
  waitingPromis = async (callBackFuction, pendingMsg, successMsg) => {
    let options = {
      pending: pendingMsg,
      success: successMsg,
      error: {
        render({ data }) {
          return `${data?.message}`;
        },
      },
    };
    await toast.promise(callBackFuction, options);
  };

  error = (message) => {};
}

export const toastAlert = new Toaster();
