import React from 'react'
import "./Dashboard.scss";

const Dashboard = () => {
    return (
        <>
            <div className='userDashboard p-5 text-center'>
                <h1>Welcome Dashboard</h1>
            </div>
        </>
    )
}

export default Dashboard
