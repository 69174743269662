import React, { useEffect, useState } from "react";
import "./ActiveAlertsTable.scss";
import CommonTable from "../../../../components/common/CommonTable/CommonTable";
import { CheckCircleIcon, CrossCircleIcon, DeleteIcon, EditIcon } from "../../../../assets/Svg/SvgImages";
import CustomSelect from "../../../../components/ui/CustomSelect/CustomSelect";
import DateTimePicker from "react-datetime-picker";
import moment from "moment-timezone";
import { TZ } from "../../../../constants/constants";
import { getDateTimeEST, parseJSON } from "../../../../helpers/commonHelper";
import { Spinner } from "react-bootstrap";

const ActiveAlertsTable = ({ conditionOptions, triggerOptions, pairOptions, onChangeFilter, alertList, pairName, loading, cancelIndex, onCancelAlert, onEditAlert }) => {
  const initFilter = (pair = null) => {
    return { pair: pair, condition: conditionOptions[0], trigger: triggerOptions[0], dateTime: "" };
  }
  const [filterOptions, setFilterOptions] = useState(initFilter())
  const onDropdownChange = (pair, key) => {
    filterOptions[key] = pair;
    setFilterOptions(parseJSON(filterOptions));
  }
  const onDateTimeChange = (e) => {
    if (!e) {
      filterOptions.dateTime = null;
      setFilterOptions(parseJSON(filterOptions));
      return;
    }
    filterOptions.dateTime = moment.tz(e, TZ).format()
    setFilterOptions(parseJSON(filterOptions));
  }
  const onFilterOrReset = (type) => {
    if (type !== "RESET") {
      onChangeFilter(filterOptions, false);
    } else {
      setFilterOptions(initFilter(pairOptions[0]));
      onChangeFilter(parseJSON(filterOptions), true);
    }
  }
  useEffect(() => {
    if (pairOptions.length) {
      filterOptions.pair = pairOptions[0];
      setFilterOptions(parseJSON(filterOptions))
    }
  }, [pairOptions])
  const Tableheading = [
    "Symbol",
    "Price",
    "Trigger",
    "Expiration",
    "Message",
    "Action",
  ];

  const activeAlertdata = [
    {
      symbol:
        pairOptions.length ?
          <CustomSelect
            placeholder=""
            value={filterOptions.pair}
            options={pairOptions}
            onChange={e => onDropdownChange(e, "pair")}
            className=""
          /> : <></>
      ,
      price:
        <CustomSelect
          placeholder=""
          value={filterOptions.condition}
          options={conditionOptions}
          onChange={e => onDropdownChange(e, "condition")}
          className=""
        />
      ,
      trigger:
        <CustomSelect
          placeholder=""
          value={filterOptions.trigger}
          options={triggerOptions}
          onChange={e => onDropdownChange(e, "trigger")}
          className=""
        />
      ,
      expiration:
        <DateTimePicker onChange={onDateTimeChange} minDate={moment().toDate()} value={filterOptions.dateTime} />
      ,
      message: "",
      checkaction: true,
    },
  ];
  return (
    <>
      <div className="activeAlertstable">
        <CommonTable className="innerCommontable AlertForm" fields={Tableheading}>
          {[...activeAlertdata].map((data, index) => {
            return (
              <tr key={index}>
                <td>
                  {data.symbol}

                </td>
                <td>{data.price}</td>
                <td>{data.trigger}</td>
                <td>{data.expiration}</td>
                <td>{data.message}</td>
                <td>
                  <div className="d-flex">
                    {loading ? <span className='LoaderIcon ps-2'>
                      <Spinner animation="border" />
                    </span> : <>
                      <button type="button" disabled={loading} onClick={() => onFilterOrReset("FILTER")}><CheckCircleIcon /></button>
                      <button type="button" disabled={loading} onClick={() => onFilterOrReset("RESET")} className="ms-5"><CrossCircleIcon /></button>
                    </>}
                  </div>
                </td>
              </tr>
            );
          })}
          {alertList.map((data, index) => {
            return (
              <tr key={index}>
                <td>
                  {pairName ? pairName.label : ''}

                </td>
                <td>{String(Number(data.price))}</td>
                <td>
                  {
                    data.alert_on === "GT"
                      ?
                      <span>Greater</span> : data.alert_on === "EQ"
                        ?
                        <span>Equal</span> : <span>Less</span>
                  }
                </td>
                <td>
                  {moment(data.expire_at).format("DD/MM/YYYY HH:mm A")}
                  {/* {data.expire_at} */}
                </td>
                <td>{data.message}</td>
                <td>
                  <div className="d-flex">
                    {
                      cancelIndex === index
                        ?
                        <span className='LoaderIcon ps-2'>
                          <Spinner animation="border" />
                        </span>
                        :
                        <button type="button" disabled={cancelIndex === index} onClick={() => onCancelAlert(data.id, filterOptions.pair.value, index)}><DeleteIcon /></button>
                    }
                    <button type="button" disabled={loading} onClick={() => onEditAlert(data)} className="ms-5"><EditIcon /></button>
                  </div>
                </td>
              </tr>
            );
          })}
        </CommonTable>
      </div>
    </>
  );
};

export default ActiveAlertsTable;
