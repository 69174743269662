import axios from "axios";
import { toastMsg } from "../components/common/Toast/Toast";
import { store } from "../app/store";
import { isUserLogin } from "../features/persist/persistSlice";

export const fetch = (method, url, data, params) => {
    let response;
    if (
        method === "GET" ||
        method === "get" ||
        method === "delete" ||
        method === "DELETE"
    ) {
        return new Promise(async (resolve, reject) => {
            try {
                response = await axios({
                    method: method,
                    url: url,
                    withCredentials: true,
                    // headers: { token: headers },
                    params: params,
                });
                resolve(response.data)
            } catch (err) {
                if (err?.response?.status === 401) {
                    store.dispatch(isUserLogin(false));
                    toastMsg.error("Session Expired");
                    window.location.href = '/'
                } else {
                    reject(err);
                }
            }
        });
    } else if (
        method === "POST" ||
        method === "post" ||
        method === "PUT" ||
        method === "put"
    ) {
        return new Promise(async (resolve, reject) => {
            try {
                response = await axios({
                    method: method,
                    url: url,
                    withCredentials: true,
                    // headers: { token: headers },
                    data: data,
                });
                resolve(response.data)
            } catch (err) {
                if (err.response.status === 401) {
                    store.dispatch(isUserLogin(false));
                    toastMsg.error("Session Expired");
                    window.location.href = '/'
                } else {
                    reject(err);
                }
            }
        });
    }
};
