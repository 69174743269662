import { fetch } from "../helpers/httpRequest";
const API_HOST = process.env.REACT_APP_API_HOST;
export const fetchPairList = () => {
    return fetch("GET", `${API_HOST}/orders/v1/order/user/pair_list`, {}, {});
};
export const createOrder = (body) => {
    return fetch(
        "POST",
        `${API_HOST}/orders/v1/order/user/create_order`,
        body,
        {}
    );
};
export const fetchOrderBooks = (body) => {
    return fetch(
        "GET",
        `${API_HOST}/orders/v1/order/user/order_book?pairName=${body.pairName}&limit=${body.limit}`,
        {},
        {}
    );
};
export const fetchMarketTrade = (body) => {
    return fetch(
        "GET",
        `${API_HOST}/orders/v1/order/user/trade?pairName=${body.pairName}&limit=${body.limit}`,
        {},
        {}
    );
};
export const login = (body) => {
    return fetch("POST", `${API_HOST}/orders/v1/order/user/login`, body, {});
};
export const logout = () => {
    return fetch("GET", `${API_HOST}/orders/v1/order/user/logout`, {}, {});
};
export const fetchGraphData = (data) => {
    const url = `${API_HOST}/graph/v1/graph/user/graph_data?interval=${data.interval}&pairName=${data.pairName}&fromDate=${data.fromDate}&toDate=${data.toDate}&limit=${data.limit}`;
    return fetch("GET", url, {}, {});
};
export const fetchNotifications = (offset, limit) => {
    const url = `${API_HOST}/orders/v1/order/user/notifications`;
    return fetch("GET", url, {}, { offset, limit });
};
export const fetchActivePairBalance = (body) => {
    const url = `${API_HOST}/wallet/v1/get_balance?baseCurrency=${body.baseCurrency}&otherCurrency=${body.otherCurrency}`;
    return fetch("GET", url, {}, {});
};
export const fetchGasPrice = () => {
    const url = `${API_HOST}/wallet/v1/get_gas_prices`;
    return fetch("GET", url, {}, {});
};
export const fetchUserOrderList = (body) => {
    const url = `${API_HOST}/orders/v1/order/user/user_order_list`;
    return fetch("GET", url, {}, body);
};
export const cancelOrder = (body) => {
    const url = `${API_HOST}/orders/v1/order/user/cancel_order`;
    return fetch("PUT", url, body, {});
};
export const fetchUserInfo = () => {
    const url = `${API_HOST}/orders/v1/order/user/user_info`;
    return fetch("GET", url, {}, {});
};
export const fetchUserTrades = (body) => {
    const url = `${API_HOST}/orders/v1/order/user/user_trade_list`;
    return fetch("GET", url, {}, body);
};

export const getWalletList = (body) => {
    const url = `${API_HOST}/wallet/v1/get_wallet_list`;
    return fetch("GET", url, {}, body);
};

export const getWalletAssetList = (body, params) => {
    const url = `${API_HOST}/wallet/v1/get_asset_list`
    return fetch("GET", url, {}, params);

};

export const updateWalletStatus = (body) => {
    const url = `${API_HOST}/wallet/v1/change_wallet_status`;
    return fetch("PUT", url, body, {});
};
export const createAlert = (body) => {
    const url = `${API_HOST}/orders/v1/order/user/add_alert`;
    return fetch("POST", url, body, {});
};
export const fetchAlerts = (body) => {
    const url = `${API_HOST}/orders/v1/order/user/fetch_alerts`;
    return fetch("GET", url, {}, body);
};
export const cancelAlert = (body) => {
    const url = `${API_HOST}/orders/v1/order/user/cancel_alert`;
    return fetch("GET", url, {}, body);
};
export const editAlert = (body) => {
    const url = `${API_HOST}/orders/v1/order/user/edit_alert`;
    return fetch("PUT", url, body, {});
};
export const editUserInfo = (body) => {
    const url = `${API_HOST}/orders/v1/order/user/update_user_info`;
    return fetch("PUT", url, body, {});
};
export const getOrderHistory = (body) => {
    const url = `${API_HOST}/orders/v1/order/user/user_order_history`;
    return fetch("GET", url, {}, body);
};
export const layout = (method, body = {}) => {
    const url = `${API_HOST}/orders/v1/order/user/layout`;
    return fetch(method, url, body, {});
};
export const getReport = (body = {}) => {
    const url = `${API_HOST}/orders/v1/order/user/report`;
    return fetch("GET", url, {}, body);
};
export const API = {
    fetchPairList,
    createOrder,
    fetchOrderBooks,
    fetchMarketTrade,
    login,
    logout,
    fetchGraphData,
    fetchNotifications,
    fetchActivePairBalance,
    fetchUserOrderList,
    cancelOrder,
    fetchUserInfo,
    fetchUserTrades,
    getWalletList,
    getWalletAssetList,
    updateWalletStatus,
    createAlert,
    fetchAlerts,
    cancelAlert,
    editAlert,
    editUserInfo,
    getOrderHistory,
    fetchGasPrice,
    layout,
    getReport
};
