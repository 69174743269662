import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Portfolio.scss";
import { useDispatch } from "react-redux";
import { ATS } from "../../features/ats/atsSlice";

import CommonSearch from "../../components/ui/CommonSearch/CommonSearch";
import WalletTable from "./WalletTable/WalletTable";
import PieAmchart from "../../components/amchart/pieAmchart";
import lineChart from "../../assets/images/linechart.svg";
import { parseJSON } from "../../helpers/commonHelper";

const Portfolio = () => {
  const dispatch = useDispatch();
  const [walletData, setWalletData] = useState([]);
  const [assetWalletData, setAssetWalletData] = useState([]);
  const [assetTotalUsdValue, setassetTotalUsdValue] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const icons = ["assets/matic.png", "assets/eth.png", "assets/apt.png"];

  const getWalletData = async () => {
    let assetData = [];
    let totalUsdValue = 0;
    const params = {};
    if (searchtext !== "") {
      params.searchText = searchtext;
    }

    let result = await dispatch(ATS.fetchWalletList(params));
    if (result.status === 200) {
      console.log("walletData ", result);
      result?.data?.wallets.forEach((ele, index) => {
        ele.tokenImg = icons[index];
      });
      console.log("resultcxcdata - ", result.data);
      setWalletData(parseJSON(result.data));

      result.data?.wallets.map((element) => {
        if (element.assetList.length > 0) {
          element.assetList.map((innerElement) => {
            //console.log("innerElement",innerElement);
            totalUsdValue =
              Number(totalUsdValue) + Number(innerElement.available_usd_value);
            assetData.push({
              asset_name: innerElement.asset_name,
              usd_value: innerElement.available_usd_value,
            });
          });
        }
      });
      totalUsdValue = Number(totalUsdValue.toFixed(4));
      setassetTotalUsdValue(totalUsdValue);
      setAssetWalletData(assetData);
    }
  };
  useEffect(() => {
    getWalletData();
  }, [searchtext]);
  return (
    <>
      <div className="userPortfolio">
        <Container>
          <Row>
            <Col xs={12} xl={7} className="d-flex">
              <div className="portfolioBalance commonCardbox w-100">
                <div className="d-flex align-items-center justify-content-between mb-4 mb-md-5">
                  <div>
                    <h6>Portfolio Balance</h6>
                    <h3>{assetTotalUsdValue} USD</h3>
                  </div>
                  <ul className="d-flex">
                    <li>
                      <button type="button">1m</button>
                    </li>
                    <li>
                      <button type="button">3m</button>
                    </li>
                    <li>
                      <button className="active" type="button">
                        6m
                      </button>
                    </li>
                    <li>
                      <button type="button">1y</button>
                    </li>
                    <li>
                      <button type="button">All</button>
                    </li>
                  </ul>
                </div>
                <img src={lineChart} className="w-100" alt="Line Chart" />
              </div>
            </Col>
            <Col xs={12} xl={5} className="d-flex">
              <div className="assetAllocation p-0 commonCardbox w-100">
                <h5>Asset Allocation</h5>
                <div className="pieChart">
                  {assetWalletData.length > 0 && (
                    <>
                      <PieAmchart
                        assetWalletData={assetWalletData}
                        assetTotalUsdValue={assetTotalUsdValue}
                      />
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <div className="totalWallet commonCardbox">
            <div className="d-sm-flex align-items-center justify-content-between mb-4 mb-md-5">
              <div className="dashHeading">
                <h3>Total Wallets</h3>
              </div>
              <CommonSearch
                className="walletSearch mt-4 mt-md-0"
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <WalletTable
              walletData={walletData}
              getWalletData={getWalletData}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Portfolio;
