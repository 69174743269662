import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    disable: false,
};

export const LoadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
        disableButton: (state) => {
            state.disable = false;
        },
        enableButton: (state) => {
            state.disable = true;
        },
    },
});

export const { startLoading, stopLoading, disableButton, enableButton } = LoadingSlice.actions;
export default LoadingSlice.reducer;
