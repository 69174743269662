import { createSlice } from "@reduxjs/toolkit";
import { CURRENT_WORKSPACE, DEFAULT_LAYOUT, FONT_LAYOUT, GRID_LAYOUT, LAYOUT_LIST } from "../../constants/grid.layout";
import { API } from "../../services/apiService";
import { toastMsg } from "../../components/common/Toast/Toast";
import { method } from "lodash";
let initialState = {
    isUserLogin: false,
    pairName: '',
    theme: "dark",
    old_grid_layout: {},
    font_layout: FONT_LAYOUT,
    mobile_tab: "TRADE",
    current_breakpoint: "lg",
    current_workspace: CURRENT_WORKSPACE,
    grid_layout: GRID_LAYOUT,
    gridLayout: GRID_LAYOUT,
    layoutList: LAYOUT_LIST,
    favPairList: [],
    userInfo: null
};

export const persistSlice = createSlice({
    name: "peristReducer",
    initialState,
    reducers: {
        isUserLogin: (state, action) => {
            state.isUserLogin = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        setPairName: (state, action) => {
            state.pairName = action.payload;
        },
        saveChangesGridLayout: (state, action) => {
            state.gridLayout = action.payload;
        },
        saveChangesGridList: (state, action) => {
            state.layoutList = action.payload;
        },
        saveOldGridLayout: (state, action) => {
            state.old_grid_layout = action.payload;
        },
        saveChangeFontSizeLayout: (state, action) => {
            state.font_layout = action.payload;
        },
        saveMobileTab: (state, action) => {
            state.mobile_tab = action.payload;
        },
        saveCurrentBreakPoint: (state, action) => {
            state.current_breakpoint = action.payload;
        },
        saveCurrentWorkspace: (state, action) => {
            state.current_workspace = action.payload;
        },
        resetLayout: (state) => {
            state.gridLayout = DEFAULT_LAYOUT;
            state.font_layout = FONT_LAYOUT;
            state.mobile_tab = "TRADE";
            state.current_breakpoint = "lg";
            state.old_grid_layout = {};
        },
        setFavPairList: (state, action) => {
            state.favPairList = action.payload;
        },
        setUesrInfo: (state, action) => {
            state.userInfo = action.payload;
        }
    },
});

export const {
    saveChangesGridLayout,
    saveChangesGridList,
    saveOldGridLayout,
    saveChangeFontSizeLayout,
    saveMobileTab,
    saveCurrentBreakPoint,
    resetLayout,
    isUserLogin,
    setFavPairList,
    setUesrInfo,
    setPairName,
    setTheme,
    saveCurrentWorkspace
} = persistSlice.actions;
export default persistSlice.reducer;
export const getUserInfo = () => dispatch => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.fetchUserInfo();
            dispatch(setUesrInfo(response.data));
            resolve(response);
        } catch (error) {
            resolve(error?.response)
            toastMsg.error(error?.response?.data?.message);
        }
    });
};
export const getUserLayout = (method, body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.layout(method, body);
            resolve(response);
        } catch (error) {
            resolve(error?.response)
            toastMsg.error(error?.response?.data?.message);
        }
    });
};