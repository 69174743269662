import React from "react";
import { Navigate, createBrowserRouter, RouterProvider } from "react-router-dom";
import { Exchange } from "../../pages";
import Login from "../../pages/Exchange/Login/Login";
import Dashboard from "../../pages/Dashboard/Dashboard";
// import { AuthGuard } from "../../guards/AuthGuard";
import { AuthLayout } from "../../components/layouts/AuthLayout/AuthLayout";
import Portfolio from "../../pages/Portfolio/Portfolio";
import PortfolioLayout from "../../pages/Portfolio/PortfolioLayout";
// import SingleWallet from "../../pages/Portfolio/PortfolioSingleWallet";
import PortfolioSingleWallet from "../../pages/Portfolio/PortfolioSingleWallet";
import Settings from "../../pages/Exchange/Settings/Settings";

const PublicRoutes = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to={'/auth/login'} replace={true} />
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: <Login />
        }
      ]
    },
    {
      path: '',
      element: <AuthLayout />,
      children: [
        {
          path: 'trade',
          element: <Exchange />
        },
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'portfolio',
          element: <PortfolioLayout />,
          children: [
            {
              index: true,
              element: <Portfolio />
            },
            {
              path: 'wallet-table',
              element: <PortfolioSingleWallet />
            },
          ],
        },
        {
          path: 'setting',
          element: <Settings />
        },
      ]
    },

  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default PublicRoutes;
