import React from 'react'
import "./CommonSearch.scss"
import { SearchIcon } from '../../../assets/Svg/SvgImages'

const CommonSearch = ({ label, placeholder, name, className, onChange, maxLength }) => {
    return (
        <>
            <div className={`commonSearch ${className}`}>
                {label && (
                    <label className='searhLabel'>{label}</label>
                )}
                <div className='searchInput'>
                    <span className='searchIcon'><SearchIcon /></span>
                    <input
                        type="text"
                        className=''
                        maxLength={maxLength}
                        id="searchbox"
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange}
                    />
                </div>
            </div>
        </>
    )
}

export default CommonSearch
