import React, { useEffect } from "react";
import "./Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Container, Form } from "react-bootstrap";
import { ATS } from "../../../features/ats/atsSlice";
import CustomInput from "../../../components/ui/CustomInput/CustomInput";
import CommonButton from "../../../components/ui/CommonButton/CommonButton";
import logo from "../../../assets/images/logo.svg";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { isUserLogin } from "../../../features/persist/persistSlice";

const Login = () => {
  const userLogin = useSelector(state => state?.persist?.isUserLogin);
  const selectedPair = useSelector(state => state?.persist?.pairName);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // if (userLogin) navigate(`/trade?symbol=${selectedPair}`);
    if (userLogin) window.location.href = `/trade?symbol=${selectedPair}`
  }, [userLogin])

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('*This Field is required'),
    password: Yup.string()
      .required('*This Field is required')
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        email: values.email.toLowerCase(),
        password: values.password,
      };
      console.log('login body => ', data)
      const result = await dispatch(ATS.loginAction(data));
      if (result.status === 200) {
        // navigate(`/trade?symbol=${result.data.pair}`);
        // dispatch(isUserLogin(true));
      }
    },
  });

  return (
    <div className="loginPage">
      <div className='text-center LoginLogo'>
        <Link to="/">
          <img src={logo} alt="logo" className="d-inline-block" />
        </Link>
      </div>
      <Container>
        <div className='loginRow d-flex'>
          {/* <div className='colLogin d-none d-md-flex px-0'>
            <div className='loginPage_loginImage'>
              <img src={loginImg} alt='img' />
            </div>
          </div> */}
          <div className='colLogin d-flex px-0'>
            <div className="loginPage_boxContent w-100">
              <div className="text-center loginHeading">
                <h1>Welcome</h1>
                <p>Be ready to explore with ATS.</p>
              </div>
              <Form onSubmit={formik.handleSubmit}>
                <CustomInput
                  label="Your Email"
                  placeholder="Enter your email"
                  className=""
                  id="email"
                  name="email"
                  type="text"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  autoFocus={true}
                  formik={formik}
                  value={formik.values.email}
                // isInvalid={formik.touched.email && !!formik.errors.email}
                // error={
                //   formik.errors.email && formik.touched.email ? (
                //     <span className="error-message">{formik.errors.email}</span>
                //   ) : null
                // }
                />
                <CustomInput
                  required={true}
                  type="password"
                  label={<span>Password <sup>*</sup></span>}
                  placeholder="******************"
                  id="password"
                  className=""
                  onChange={formik.handleChange}
                  autoFocus={true}
                  formik={formik}
                  value={formik.values.password}
                  // isInvalid={formik.touched.password && !!formik.errors.password}
                  // error={
                  //   formik.errors.password && formik.touched.password ? (
                  //     <span className="error-message">{formik.errors.password}</span>
                  //   ) : null
                  // }
                  preventCopyPaste={true}
                />
                <div className="w-100 loginBtn">
                  <CommonButton type="submit" disabled={!formik.isValid} title="Log In" fluid />
                </div>
                <div className="anAccount text-center">
                  <h6>
                    Don’t have an account? <Link to="#">Contact Us.</Link>
                  </h6>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>

      <footer className='loginFooter'>
        <p>Copyright © 2024 Oasis Pro Markets. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Login;
