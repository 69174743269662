import React, { useEffect } from "react";
import "./SnigleWalletTable.scss";
import CommonTable from "../../../components/common/CommonTable/CommonTable";
import ReactPagination from "../../../components/common/pagination";

const SnigleWalletTable = (props) => {
  const { walletAssetData, limit, pageNumber, setpageNumber } = props
  const Tableheading = [
    "Asset Name",
    "My Balance",
    "Lock-In",
    "Price",
    "Value",
  ];
  const handlePageChange = ({ selected }) => {
    const currentPage = selected + 1;
    setpageNumber(currentPage);
  };

  console.log("sss", walletAssetData?.totalItems);
  return (
    <>
      <div className="singleWalletTable">
        <CommonTable className="innerCommontable" fields={Tableheading}>
          {walletAssetData?.assetList?.length > 0 && walletAssetData?.assetList?.map((data, index) => {
            let lockedBalance = (Number(data?.total_balance) - Number(data.available_balance))
            lockedBalance = (lockedBalance === 0) ? 0 : lockedBalance.toFixed(4)
            return (
              <tr key={index}>
                <td>{data.asset_name}</td>
                <td>{(Number(data?.available_balance)?.toFixed(4))}</td>
                <td>{lockedBalance}</td>
                <td>{(Number(data?.currentPrice)?.toFixed(4))}</td>
                <td>{data.available_usd_value}</td>
              </tr>
            );
          })}
        </CommonTable>
        {<div className="text-end w-100">
          {walletAssetData?.totalItems > limit && (
            <ReactPagination
              handlePageChange={handlePageChange}
              activityList={walletAssetData?.totalItems}
              limit={limit}
              forcePage={pageNumber - 1}
            />
          )}
        </div>}
      </div>
    </>
  );
};

export default SnigleWalletTable;
