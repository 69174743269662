import React, { useEffect, useRef, useState } from 'react'
import "./CreateAlert.scss"
import { useFormik } from 'formik';
import * as Yup from "yup";
import CommonButton from '../../../../components/ui/CommonButton/CommonButton';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import CustomSelect from '../../../../components/ui/CustomSelect/CustomSelect';
import "./CreateAlert.scss"
import CustomInput from '../../../../components/ui/CustomInput/CustomInput';
import Checkbox from '../../../../components/ui/FormInputs/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { getDateTimeEST, inputDecimal, parseJSON, toFix } from '../../../../helpers/commonHelper';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment-timezone';
import { TZ } from '../../../../constants/constants';
import { ATS } from '../../../../features/ats/atsSlice';
import { toastMsg } from '../../../../components/common/Toast/Toast';
import { getUserInfo, setUesrInfo } from '../../../../features/persist/persistSlice';

function CreateAlert({ onAlertCreate, editBody, onEditAlert }) {
    const dispatch = useDispatch();
    const [selectedPair, updateSelectedPair] = useState(null);
    const [alertPair, setAlertPair] = useState(null);
    const [alertOn, setAlertOn] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [dateTime, setDateTime] = useState(moment().toDate());
    const pairList = useSelector(state => state?.ats?.pairList);
    const userInfo = useSelector(state => state?.persis?.userInfo);
    const [isSubmitted, setIsSubmited] = useState(false);
    const [pairOptions, setPairOptions] = useState([]);
    const validationSchema = Yup.object({
        pairName: Yup.string()
            .required(),
        price: Yup.string()
            .required(),
        alert_on: Yup.string()
            .required(),
        trigger_alert: Yup.string()
            .required(),
        subject: Yup.string()
            .required(),
        message: Yup.string()
            .required()
    });
    const onCancelRequest = () => {
        resetForm();
        onAlertCreate({ isEdit: false, isCancel: true });
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            pairName: editBody ? editBody.pairName : '',
            price: editBody ? String(Number(editBody.price)) : '',
            alert_on: editBody ? editBody.alert_on : '',
            trigger_alert: editBody ? editBody.trigger_alert : 'ONCE',
            expire_at: "",
            subject: editBody ? editBody.subject : '',
            message: editBody ? editBody.message : ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const body = parseJSON(values);
            body.price = Number(body.price);
            body.expire_at = moment.utc(dateTime).format();
            if (!dateTime) return toastMsg.error("Please select expiry date time.");
            console.log(body);
            if (editBody) {
                await updateAlert(body);
            } else {
                await addAlert(body);
            }
        }
    });
    useEffect(() => {
        if (editBody) {
            const selectedPair = pairList.find(o => o.pair_key === editBody.pairName);
            const pair = pairOptions.find(o => o.value === editBody.pairName);
            const condition = options.find(o => o.value === editBody.alert_on);
            setAlertPair(pair);
            setAlertOn(condition);
            setDateTime(moment(editBody.expire_at).toDate())
            updateSelectedPair(selectedPair);
        }
    }, [editBody])
    const resetForm = () => {
        formik.resetForm();
        setAlertPair(null);
        setAlertOn(null);
        formik.setFieldValue("expire_at", getDateTimeEST())
    }
    const addAlert = async (body) => {
        setIsSubmited(true);
        const response = await ATS.addAlert(body);
        if (response.status === 200) {
            setIsSubmited(false);
            onAlertCreate({ isEdit: false, isCancel: false });
            resetForm();
        } else {
            setIsSubmited(false);
        }
    }
    const updateAlert = async (body) => {
        body.id = editBody.id;
        setIsSubmited(true);
        const response = await ATS.updateAlert(body);
        if (response.status === 200) {
            setIsSubmited(false);
            onAlertCreate({ isEdit: true, isCancel: false });
            resetForm();
        } else {
            setIsSubmited(false)
        }
    }
    const options = [
        { value: 'EQ', label: 'Equal' },
        { value: 'GT', label: 'Greater' },
        { value: 'LT', label: 'Less' }
    ]
    useEffect(() => {
        if (pairList.length) {
            const pairs = [];
            pairList.forEach(ele => {
                pairs.push({ value: ele.pair_key, label: `${ele.otherCurrency}/${ele.baseCurrency}` })
            });
            updateSelectedPair(pairList[0]);
            setAlertOn(options[0])
            setPairOptions(parseJSON(pairs));
            setAlertPair(pairs[0]);
            formik.setFieldValue("pairName", pairs[0].value);
            formik.setFieldValue("alert_on", options[0].value);
        }
    }, [pairList]);
    const onPairChange = (e) => {
        const pair = pairList.find(o => o.pair_key === e.value);
        setAlertPair(e);
        updateSelectedPair(pair);
        formik.setFieldValue("pairName", e.value)
    }
    const handleOnChange = e => {
        e.preventDefault();
        const { value } = e.target;
        const regex = /^\d+\.?\d*$/;
        if (regex.test(value)) {
            const decimal = e.target.name === 'price' ? selectedPair.price_decimal : selectedPair.quantity_decimal;
            const endValue = inputDecimal(value, decimal);
            formik.setFieldValue(e.target.name, endValue);
        } else {
            if (value.length === 0) formik.setFieldValue(e.target.name, "");
        }
    };
    const onChangeCheckBox = async (e) => {
        const user = parseJSON(userDetails);
        user[e.target.name] = e.target.checked ? 1 : 0;
        const body = { notify_email: user.notify_email, notify_popup: user.notify_popup, notify_sms: user.notify_sms };
        const response = await dispatch(ATS.editUserInfo(body));
        if (response.status === 200) {
            user.notify_email = response.data.notify_email;
            user.notify_popup = response.data.notify_popup;
            user.notify_sms = response.data.notify_sms;
            setUesrInfo(user);
            setUserDetails(user);
        }
    }
    const getUserProfile = async () => {
        const response = await dispatch(getUserInfo());
        if (response.status === 200) {
            setUserDetails(response.data);
        }
    }
    useEffect(() => {
        getUserProfile();
    }, [])
    return (
        <>
            <div className='createAlert'>
                <Form className='AlertForm' onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={8} xl={5} xxl={4}>
                            <Row className=''>
                                <Col xs={12} lg={3} xl={3}>
                                    <label>Instrument*</label>
                                </Col>
                                <Col xs={12} lg={9} xl={9}>
                                    <CustomSelect
                                        placeholder="Select Pair"
                                        options={pairOptions}
                                        onChange={onPairChange}
                                        name="pairName"
                                        disabled={isSubmitted}
                                        className="mb-4"
                                        value={alertPair}
                                        formik={formik}
                                    // menuIsOpen={true}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col xs={12} lg={3} xl={3}>
                                    <label>Condition*</label>
                                </Col>
                                <Col xs={12} lg={9} xl={9}>
                                    <CustomSelect
                                        placeholder="Select Type"
                                        options={options}
                                        onChange={e => { formik.setFieldValue("alert_on", e.value); setAlertOn(e); }}
                                        name="alert_on"
                                        disabled={isSubmitted}
                                        value={alertOn}
                                        formik={formik}
                                    // menuIsOpen={true}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col xs={12} lg={3} xl={3}>
                                    <label>Price*</label>
                                </Col>
                                <Col xs={12} lg={9} xl={9}>
                                    <CustomInput
                                        // id="price"
                                        name="price"
                                        type="text"
                                        onChange={(e) => handleOnChange(e)}
                                        onBlur={formik.handleBlur}
                                        autoFocus={true}
                                        maxLength={8}
                                        value={formik.values.price}
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-4 triggerAlert'>
                                <Col xs={12} lg={3} xl={3}>
                                    <label>Trigger</label>
                                </Col>
                                <Col xs={12} lg={9} xl={9}>
                                    <div className='triggerTabs d-flex'>
                                        <Button type='button' disabled={isSubmitted} onClick={() => formik.setFieldValue("trigger_alert", "ONCE")} className={`btn-style ${formik.values.trigger_alert === 'ONCE' ? 'active' : ''}`}>Only Once</Button>
                                        <Button type='button' disabled={isSubmitted} onClick={() => formik.setFieldValue("trigger_alert", "REPEAT")} className={`btn-style ${formik.values.trigger_alert === 'REPEAT' ? 'active' : ''}`}>Every Time</Button>
                                    </div>
                                    <p>The alert will only trigger once and will not be repeated</p>
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col xs={12} lg={3} xl={3}>
                                    <label>Expiration*</label>
                                </Col>
                                <Col xs={12} lg={9} xl={9}>
                                    <DateTimePicker disabled={isSubmitted} locale='IST' format='MM/dd/yyyy HH:mm a' onChange={setDateTime} minDate={moment().toDate()} value={dateTime} />
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col xs={12} lg={3} xl={3}>
                                    <label>Subject*</label>
                                </Col>
                                <Col xs={12} lg={9} xl={9}>
                                    <CustomInput
                                        id="subject"
                                        name="subject"
                                        type="text"
                                        placeholder=""
                                        disabled={isSubmitted}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoFocus={true}
                                        value={formik.values.subject}
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col xs={12} lg={3} xl={3}>
                                    <label>Message*</label>
                                </Col>
                                <Col xs={12} lg={9} xl={9}>
                                    <textarea rows={7} disabled={isSubmitted} onBlur={formik.handleBlur} value={formik.values.message} formik={formik} name='message' onChange={e => formik.setFieldValue("message", e.target.value)} className='form-control mb-3'></textarea>
                                    <p>You can use special placeholders such as etc.</p>
                                    {/* {{close}}, {{time}}, {{plot_0}} */}
                                    <div className="d-flex alertBtns mt-5">
                                        <CommonButton type="button" disabled={isSubmitted} onClick={onCancelRequest} className="me-1 me-md-3 white-btn" title="Cancel" />
                                        {editBody
                                            ?
                                            <CommonButton type="submit" disabled={!formik.isValid || !dateTime || isSubmitted} className="ms-1 ms-md-3" title={isSubmitted ? <span className='LoaderIcon ps-2'>
                                                <Spinner animation="border" />
                                            </span> : "Update"} />
                                            :
                                            <CommonButton type="submit" disabled={!formik.isValid || !dateTime || isSubmitted} className="ms-1 ms-md-3" title={isSubmitted ? <span className='LoaderIcon ps-2'>
                                                <Spinner animation="border" />
                                            </span> : "Create"} />
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <div className='alertNotification'>
                    <Row className=''>
                        <Col xs={12} lg={2} xl={1}>
                            <label>Notifications</label>
                        </Col>
                        <Col xs={12} lg={10} xl={11}>
                            <Row className='align-items-center'>
                                <Col xs={12} lg={12} xl={9}>
                                    <ul className='d-md-flex'>
                                        <li>
                                            <Checkbox
                                                label="Notify in Mobile"
                                                name="notify_sms"
                                                defaultChecked={Number(userDetails?.notify_sms) === 1 ? true : false}
                                                onChange={onChangeCheckBox}
                                            />
                                            <p>Provides a push notification</p>
                                        </li>
                                        <li>
                                            <Checkbox
                                                label="Show pop-up"
                                                name="notify_popup"
                                                defaultChecked={Number(userDetails?.notify_popup) === 1 ? true : false}
                                                onChange={onChangeCheckBox}
                                            />
                                            <p>Display an onsite pop-up notification.</p>
                                        </li>
                                        <li>
                                            <Checkbox
                                                label="Send email"
                                                name="notify_email"
                                                defaultChecked={Number(userDetails?.notify_email) === 1 ? true : false}
                                                onChange={onChangeCheckBox}
                                            />
                                            <p>Provides an email notification to the address specified in your profile settings.</p>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default CreateAlert
