import React, { useState } from "react";
import "./WalletTable.scss";
import { CircleIcon, CopyIcon } from "../../../assets/Svg/SvgImages";
import CommonTable from "../../../components/common/CommonTable/CommonTable";
import Checkbox from "../../../components/ui/FormInputs/Checkbox";
import { useDispatch } from "react-redux";
import { ATS } from "../../../features/ats/atsSlice";
import { Link } from "react-router-dom";
import ReactPagination from "../../../components/common/pagination";
import { copyToClipboard } from "../../../helpers/commonHelper";
import { useEffect } from "react";
import { blockchainOptions } from "../../../constant";

const WalletTable = (props) => {
  const { getWalletData } = props;
  const dispatch = useDispatch();

  const walletData = props.walletData;
  const [page, setPage] = useState(1);
  const limit = 5;
  const indexOfLastRecord = page * limit;
  const indexOfFirstRecord = indexOfLastRecord - limit;
  console.log("indexOfLastRecord", { indexOfLastRecord, indexOfFirstRecord });
  const Tableheading = [
    "",
    "Wallet Name",
    "Network",
    "Address",
    "No. of Tokens",
    "Chain",
    "Status",
  ];

  const handlePageChange = ({ selected }) => {
    const currentPage = selected + 1;
    setPage(currentPage);
  };

  const handleWalletStatus = (e, data) => {
    let checked = e.target.checked;
    // const currentPage = selected + 1;
    // setPage(currentPage);
    getUpadateWalletStatus(checked, data);
  };

  const getUpadateWalletStatus = async (status, data) => {
    let payloads = {
      status: status === true ? 1 : 0,
      address: data.address,
    };
    dispatch(ATS.updateWalletStatus(payloads)).then(
      (res) => {
        getWalletData();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const SelectStatus = (value) => {
    switch (value) {
      case 1:
        return "Pending";
      case 2:
        return "Activated";
      default:
        return "Rejected";
    }
  };
  // useEffect(() => {
  //   alert(walletData.length)
  // }, [props])
  return (
    <>
      <div className="walletTable">
        <CommonTable className="innerCommontable" fields={Tableheading}>
          {walletData?.wallets?.length > 0 &&
            walletData?.wallets
              ?.slice(indexOfFirstRecord, indexOfLastRecord)
              ?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Checkbox
                        defaultChecked={data?.is_active === 1 ? true : false}
                        onChange={(e) => {
                          handleWalletStatus(e, data);
                        }}
                      />
                    </td>
                    <td>
                      {" "}
                      <Link
                        to={`wallet-table`}
                        state={{
                          wallet_id: data.wallet_id,
                          wallet_name: data.wallet_name,
                          vault_id: data.vault_id,
                        }}
                      >
                        {data.wallet_name}
                      </Link>
                    </td>
                    <td>{data.network_id}</td>

                    <td>
                      {data.address}{" "}
                      <span
                        className="copyIcon ms-3 ms-md-4"
                        onClick={() => copyToClipboard(data.address)}
                      >
                        <CopyIcon />
                      </span>
                    </td>
                    <td>{data.count_of_token}</td>
                    <td>
                      <img
                        className="chain_Token"
                        src={
                          blockchainOptions?.find((chain) =>
                            data?.wallet_name?.includes(chain.label)
                          )?.icon
                        }
                        alt="icon"
                      />
                      <span className="ms-3">{data.chain}</span>
                    </td>
                    <td>
                      {/* <CommonButton className="white-btn" title="Action" onlyIcon={<CircleIcon />} /> */}
                      <div className="tableBtn white-btn">
                        <CircleIcon /> {SelectStatus(data.status)}
                      </div>
                    </td>
                  </tr>
                );
              })}
        </CommonTable>

        <div className="text-end w-100">
          {walletData?.wallets?.length > limit && (
            <ReactPagination
              handlePageChange={handlePageChange}
              activityList={walletData?.wallets.length}
              limit={limit}
              forcePage={page - 1}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WalletTable;
