import { createSlice } from "@reduxjs/toolkit";
import { toastMsg } from "../../components/common/Toast/Toast";
import { startLoading, stopLoading } from "../loading/loadingSlice";
import { API } from "../../services/apiService";
import { isUserLogin, saveChangesGridLayout, setPairName } from "../persist/persistSlice";
import { parseJSON } from "../../helpers/commonHelper";
const initialState = {
    pairList: [],
    selectedPair: null,
    buyOrderBook: [],
    sellOrderBook: [],
    tradeBook: [],
    lastTrade: null,
    lastPrice: 0,
    activePairBalance: null,
    report: null
};
export const atsSlice = createSlice({
    name: "ats",
    initialState,
    reducers: {
        setPairList: (state, action) => {
            state.pairList = action.payload;
        },
        setSelectedPair: (state, action) => {
            state.selectedPair = action.payload;
        },
        setBuyOrderBook: (state, action) => {
            state.buyOrderBook = action.payload;
        },
        setSellOrderBook: (state, action) => {
            state.sellOrderBook = action.payload;
        },
        setTradeBook: (state, action) => {
            state.tradeBook = action.payload;
        },
        setLastTrade: (state, action) => {
            state.lastTrade = action.payload;
        },
        setLastPrice: (state, action) => {
            state.lastPrice = action.payload;
        },
        setActivePairBalance: (state, action) => {
            state.activePairBalance = action.payload;
        },
        setReport: (state, action) => {
            state.report = action.payload;
        }
    },
});
export const {
    setPairList,
    setSelectedPair,
    setBuyOrderBook,
    setSellOrderBook,
    setTradeBook,
    setLastTrade,
    setLastPrice,
    setActivePairBalance,
    setReport
} = atsSlice.actions;
export default atsSlice.reducer;
const fetchPairList = pair => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatch(startLoading());
            const response = await API.fetchPairList();
            dispatch(setPairList(response.data.pairList));
            if (response.data.pairList.length) {
                if (window.location.pathname === '/trade') {
                    setPair(pair, response.data.pairList, dispatch);
                } else {
                    dispatch(setPairName(response.data.pairList[0].pair_key))
                    dispatch(setSelectedPair(parseJSON(response.data.pairList[0])))
                }
            }
            resolve(response.data.data);
            dispatch(stopLoading());
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
            dispatch(stopLoading());
        }
    });
};
const placeOrder = (body) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            // dispatch(startLoading());
            const response = await API.createOrder(body);
            toastMsg.success(response.message);
            resolve(response);
            // dispatch(stopLoading());
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
            resolve(error);
            // dispatch(stopLoading());
        }
    });
};
const fetchBuySellOrderBook = (body) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            // dispatch(startLoading());
            const response = await API.fetchOrderBooks(body);
            dispatch(setBuyOrderBook(response.data.buyOrderBook));
            dispatch(setSellOrderBook(response.data.sellOrderBook?.reverse()));
            resolve(response);
            // dispatch(stopLoading());
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
            // dispatch(stopLoading());
        }
    });
};
const fetchMarketTrade = (body) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            // dispatch(startLoading());
            const response = await API.fetchMarketTrade(body);
            dispatch(setTradeBook(response.data.tradeList));
            resolve(response);
            // dispatch(stopLoading());
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
            // dispatch(stopLoading());
        }
    });
};
const loginAction = (body) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatch(startLoading());
            const response = await API.login(body);
            console.log("Login Response -> ", response);
            if (response.status === 200) {
                dispatch(setPairName(response.data.pair))
                dispatch(isUserLogin(true));
            }
            resolve(response);
            dispatch(stopLoading());
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
            dispatch(stopLoading());
        }
    });
};
const logout = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatch(startLoading());
            const response = await API.logout();
            console.log("Logout Response -> ", response);
            dispatch(isUserLogin(false));
            dispatch(setPairName(""));
            // if (response.status === 200) dispatch(isUserLogin(false));
            resolve(response);
            dispatch(stopLoading());
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
            dispatch(stopLoading());
        }
    });
};
const getNotifications = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.fetchNotifications(body.offset, body.limit);
            resolve(response);
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
        }
    });
};
const fetchUserBalance = (body) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            // dispatch(startLoading());
            const response = await API.fetchActivePairBalance(body);
            dispatch(setActivePairBalance(response.data));
            // dispatch(stopLoading());
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
            // dispatch(stopLoading());
        }
    });
};
const fetchUserOrderList = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.fetchUserOrderList(body);
            resolve(response);
        } catch (error) {
            resolve(error.response)
            toastMsg.error(error?.response?.data?.message);
        }
    });
};
const fetchUserOrderHistory = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.getOrderHistory(body);
            resolve(response);
        } catch (error) {
            resolve(error.response)
            toastMsg.error(error?.response?.data?.message);
        }
    });
};
const cancelUserOrder = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.cancelOrder(body);
            toastMsg.success(response.message);
            resolve(response);
        } catch (error) {
            resolve(error?.response)
            toastMsg.error(error?.response?.data?.message);
        }
    });
};
const getUserTrades = (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.fetchUserTrades(body);
            resolve(response);
        } catch (error) {
            resolve(error?.response)
            toastMsg.error(error?.response?.data?.message);
        }
    });
};
const setPair = (pair, pairList, dispatch) => {
    if (pair) {
        const index = pairList.findIndex(o => o.pair_key.toLowerCase() === pair.toLowerCase());
        if (index > -1) {
            dispatch(setPairName(pairList[index].pair_key))
            dispatch(setSelectedPair(parseJSON(pairList[index])))
        } else {
            dispatch(setPairName(pairList[0].pair_key))
            dispatch(setSelectedPair(parseJSON(pairList[0])))
        }
    } else {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('symbol', pairList[0].pair_key);
        window.location.search = urlParams;
        dispatch(setSelectedPair(parseJSON(pairList[0])))
    }
}

const fetchWalletList = (body, params) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatch(startLoading());
            const response = await API.getWalletList(body, params);
            resolve(response)
            dispatch(stopLoading());
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
            dispatch(stopLoading());
        }
    });
};

const fetchWalletAssetList = (param) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatch(startLoading());
            const response = await API.getWalletAssetList({}, param);
            resolve(response)
            dispatch(stopLoading());
        } catch (error) {
            toastMsg.error(error?.response?.data?.message);
            dispatch(stopLoading());
        }
    });
};


const updateWalletStatus = (body) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatch(startLoading());
            const response = await API.updateWalletStatus(body);
            console.log("ddd", response);
            resolve(response)
            dispatch(stopLoading());
        } catch (error) {
            console.log("EROR", error)
            toastMsg.error(error?.response?.data?.message);
            dispatch(stopLoading());
        }
    });
};
const addAlert = async (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.createAlert(body);
            toastMsg.success(response.message);
            resolve(response);
        } catch (error) {
            console.log("EROR", error)
            toastMsg.error(error?.response?.data?.message);
            resolve(error?.response?.data);
        }
    })
}
const fetchAlerts = async (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.fetchAlerts(body);
            resolve(response);
        } catch (error) {
            console.log("EROR", error)
            toastMsg.error(error?.response?.data?.message);
            reject(error?.response?.data);
        }
    })
}
const cancelAlert = async (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.cancelAlert(body);
            resolve(response);
        } catch (error) {
            console.log("EROR", error)
            toastMsg.error(error?.response?.data?.message);
            resolve(error?.response?.data);
        }
    })
}
const updateAlert = async (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.editAlert(body);
            toastMsg.success(response.message);
            resolve(response);
        } catch (error) {
            console.log("EROR", error)
            toastMsg.error(error?.response?.data?.message);
            resolve(error?.response?.data);
        }
    })
}
const getGasPrice = async (body) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.fetchGasPrice();
            resolve(response);
        } catch (error) {
            console.log("EROR", error)
            toastMsg.error(error?.response?.data?.message);
            resolve(error?.response?.data);
        }
    })
}
const editUserInfo = body => dispatch => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatch(startLoading());
            const response = await API.editUserInfo(body);
            toastMsg.success(response.message);
            resolve(response);
            dispatch(stopLoading());
        } catch (error) {
            console.log("EROR", error)
            toastMsg.error(error?.response?.data?.message);
            resolve(error?.response?.data);
            dispatch(stopLoading());
        }
    })
}
const fetchReport = pairName => dispatch => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatch(startLoading());
            const response = await API.getReport({ pairName });
            dispatch(setReport(response.data));
            resolve(response);
            dispatch(stopLoading());
        } catch (error) {
            console.log("EROR", error)
            toastMsg.error(error?.response?.data?.message);
            resolve(error?.response?.data);
            dispatch(stopLoading());
        }
    })
}

export const ATS = {
    fetchPairList,
    placeOrder,
    fetchBuySellOrderBook,
    fetchMarketTrade,
    loginAction,
    logout,
    getNotifications,
    fetchUserBalance,
    fetchUserOrderList,
    fetchUserOrderHistory,
    cancelUserOrder,
    getUserTrades,
    fetchWalletList,
    fetchWalletAssetList,
    updateWalletStatus,
    addAlert,
    fetchAlerts,
    cancelAlert,
    updateAlert,
    editUserInfo,
    getGasPrice,
    fetchReport
};
