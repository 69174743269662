import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PublicRoutes } from "./routes/";
import packageJson from "../package.json";

const Application = () => {
  const theme = useSelector((state) => state.persist.theme);
  useEffect(() => {
    checkIfVersionNotSame();
  }, []);

  const checkIfVersionNotSame = () => {
    let version = localStorage.getItem("version");
    console.log("localVersion", version);
    console.log("build version", packageJson.version);

    if (version !== packageJson.version) {
      console.log("Cache cleared");
      // dispatch({ type: "RESET_LAYOUT" });
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem("version", packageJson.version);
    }
  };
  useEffect(() => {
    document.body.className = `${theme}-theme`;
  }, [theme]);
  return (
    <>
      <PublicRoutes />
    </>
  );
};

export default Application;
