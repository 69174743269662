import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import React, { useEffect } from 'react'
import { getColors } from "../../helpers/commonHelper";

function PieAmchart(props) {
  const { assetWalletData, assetTotalUsdValue } = props
  const colors = [];
  const dataList = [];
  if (assetWalletData && assetWalletData.length) {
    // assetWalletData.forEach((ele, index) => {
    //   ele.usd_value = 14
    //   if (index == (assetWalletData.length - 1)) ele.usd_value = 16;
    // });
    console.log('asset ', assetWalletData)
    const bgColors = getColors();
    assetWalletData.forEach((ele, index) => {
      if (Number(ele.usd_value) > 0) {
        dataList.push(ele)
        colors.push(am5.color(bgColors[index]));
      }
    });
  }
  const amchartFunction = () => {
    let root = am5.Root.new("chartdiv");

    root._logo.dispose();
    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        innerRadius: am5.percent(90),
        // radius: am5.percent(70)

      })
    );
    chart.set("scrollbarY", am5.Scrollbar.new(root, { orientation: "vertical" }));
    let series0 = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "usd_value",
        categoryField: "asset_name",
        alignLabels: false,
        legendValueText: null
      })
    );

    series0.labels.template.adapters.add("y", function (y, target) {
      let dataItem = target.dataItem;
      if (dataItem) {
        let tick = dataItem.get("tick");
        if (tick) {
          if (dataItem.get("valuePercentTotal") < 1) {
            target.set("forceHidden", true);
            tick.set("forceHidden", true);
          } else {
            target.set("forceHidden", false);
            tick.set("forceHidden", true);
          }
        }
      }
      return y;
    });

    series0.labels.template.setAll({
      // textType: "circular",
      centerX: 0,
      centerY: 0,
    });

    series0.get("colors").set("colors", colors);

    let label = chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: "center",
        centerY: am5.p50,
        centerX: am5.p50,
        text: `[fontSize:14px]Portfolio Balance[/]:\n[bold fontSize:25px]${assetTotalUsdValue}USD[/]`,
        fill: am5.color("#DDC24C"),
      })
    );
    var bgColor = root.interfaceColors.get("background");
    series0.data.setAll(dataList);
    series0.slices.template.setAll({
      // stroke: bgColor,
      // stroke: am5.color(0x161A1E),
      tooltipText:
        "{category}: {valuePercentTotal.formatNumber('0.00')}% ({value}USD)"
    });

    series0.labels.template.setAll({
      fontSize: 12,
      text: "",
      textType: "radial",
      centerX: am5.percent(100),
      fill: am5.color(0xffffff)
    })

    series0.labels.template.set("forceHidden", true);
    series0.ticks.template.set("forceHidden", true);
    // Add legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerY: am5.percent(50),
      y: am5.percent(50),
      layout: root.verticalLayout
    }));
    legend.labels.template.set("fill", am5.color("#dfdfdf"));
    legend.data.setAll(series0.dataItems);

    return () => {
      root.dispose()
    };
  }

  useEffect(() => {
    amchartFunction()

  }, [])
  return (
    <div id="chartdiv" className="atsPiechart"></div>

  )
}

export default PieAmchart