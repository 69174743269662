import React, { useEffect } from "react";
import styles from "./Loader.module.scss";
import { useSelector } from "react-redux";
import logo from "../../../assets/images/logo.svg";

const Loader = () => {
    const { loading } = useSelector((state) => state.loading);
    useEffect(() => {
        document.body.style.overflow = loading ? "hidden" : "visible";
    }, [loading]);
    if (loading) {
        return (
            <>
                <span className={styles.loader}>
                    <img src={logo} alt="white-logo" />
                </span>
            </>
        );
    } else {
        return null;
    }
};

export default Loader;
